import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../layout";
import NewsPost from "../components/NewsPost/NewsPost"
import SectionHeader from "../components/SectionHeader/SectionHeader"
import "./news.scss"

export default function NewsPage({data}) {

    const posts = data.allMarkdownRemark.nodes.map( (newsNode)=>{
         return (<NewsPost newsNode={newsNode}></NewsPost>)
    })

    return (
      <Layout>
        <div className="newsPage__container">
            <SectionHeader className="newsPage__pageTitle">Latest Team Max News</SectionHeader>
          <Helmet title={`Latest News | Team Max`} />
          {posts}
        </div>
      </Layout>
    );
}


export const pageQuery = graphql`
    query newsQuery {
      allMarkdownRemark(filter: {frontmatter: {type: {eq: "News"}}},
      sort: {fields: frontmatter___date}) {
      nodes {
          excerpt
          frontmatter {
              path
              remoteurl
              title
              date(formatString: "dddd LL")
              address
              round
              featured {
                  publicURL
              }
          }
      }
    }
  }
  `

