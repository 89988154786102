import React from "react"
import IconButton from "../IconButton/IconButton"
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import "./newsPost.scss"
        
export default function NewsPost({
    newsNode
}){
    const {excerpt, frontmatter} = newsNode
    return(
        <div className="newsPost">
          {frontmatter?.featured && <img className="newsPost__featured" src={frontmatter.featured.publicURL} alt={frontmatter.title}/>}
          <h3>{frontmatter.title}</h3>
          <p>{frontmatter.date}</p>
          <div
            className="newsPost__content"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
          <IconButton 
            className="newsPost__iconButton"
            linkText="Read more"
            linkTarget={frontmatter.path}
            imgIconFa={faTrophy}
          />
        </div>
    )
}